@font-face {
  font-family: "Meche Pro";
  src: url("./assets/fonts/meche-pro/mechepro-black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Meche Pro";
  src: url("./assets/fonts/meche-pro/mechepro-blackstencil.otf")
    format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Meche Pro";
  src: url("./assets/fonts/meche-pro/mechepro-bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Meche Pro";
  src: url("./assets/fonts/meche-pro/mechepro-boldstencil.otf")
    format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Meche Pro";
  src: url("./assets/fonts/meche-pro/mechepro-light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Meche Pro";
  src: url("./assets/fonts/meche-pro/mechepro-lightstencil.otf")
    format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Meche Pro";
  src: url("./assets/fonts/meche-pro/mechepro-medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Meche Pro";
  src: url("./assets/fonts/meche-pro/mechepro-mediumstencil.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Meche Pro";
  src: url("./assets/fonts/meche-pro/mechepro-regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Meche Pro";
  src: url("./assets/fonts/meche-pro/mechepro-regularstencil.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Meche Pro";
  src: url("./assets/fonts/meche-pro/mechepro-thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Meche Pro";
  src: url("./assets/fonts/meche-pro/mechepro-thinstencil.otf")
    format("opentype");
  font-weight: 100;
  font-style: normal;
}
